.d-tooltip {
  &__hidden-clickable {
    @apply w-50px;
    @apply h-[30px];
    @apply absolute;
    @apply top-[-21px];
    @apply left-[8px];
    @apply z-0;
    @apply opacity-0;
  }

  &__text-wrapper {
    &:hover {
      @apply underline;
    }
  }
}
