@use '/styles/mixin/fonts.module.scss' as *;

.drive-enquiry {
  &__wrapper {
    @apply z-10;
    @apply relative;
    @apply h-screen;
    @apply w-full;
    height: stretch;
    @apply top-0;
    @apply flex;
    @apply flex-col;
    @apply items-end;
    @apply overflow-y-scroll;
    scrollbar-width: none; // Hide scrollbar for Firefox
    @apply min-h-[657px];
    @screen md {
      @apply my-8;
      @apply max-w-[803px];
    }
    @screen lg {
      @apply pr-10;
      @apply min-h-72;
      @apply overflow-hidden;
      @apply my-0;
      @apply top-auto;
      @apply h-auto;
      @apply px-0;
    }
  }
  /* Hide scrollbar for WebKit browsers */
  &__wrapper::-webkit-scrollbar {
    @apply hidden;
  }

  &__close-btn-wrapper {
    @apply flex;
    @apply justify-center;
    @apply items-start;
    @apply mt-4;
    @apply -mb-9;
    @apply mr-4;
    @apply z-10;
    @apply cursor-pointer;
    img {
      @apply w-6;
      @apply h-6;
    }
  }

  &__container {
    @apply w-full;
    @apply bg-blue-50;
    @apply rounded-2xl;
    @apply p-[18px];
    @apply pt-3;
    @apply pb-7;
    @screen lmd {
      @apply p-8;
    }
  }

  &__header {
    @apply mt-4;
    @apply flex;
    @apply justify-center;
    @screen lmd {
      @apply justify-start;
      @apply mt-0;
    }

    :global(.drive-icon) {
      @apply hidden;
      @screen lmd {
        @apply inline;
        @apply mr-4;
      }
    }
    h3 {
      @apply text-center;
      @screen lmd {
        @apply m-0;
        @include font-heading('desktop', 'h5');
      }
    }
  }

  //define overriding style here for higher specificity
  &__wrapper {
    // match confirmation body if header presents, remove the top border radius
    [class^='headerSection_drive-header-section__container']
      + [class^='confirmation-message_drive-cfs-form__confirmation-message'] {
      [class^='confirmation-message_drive-cfs__confirmation-message__wrapper'] {
        @apply rounded-t-none;
        @apply border-t-0;
      }
    }
    // match enquiry body if header presents, remove the top border radius
    [class^='headerSection_drive-header-section__container']
      + [class^='enquiryForm_drive-enquiry__container'] {
      @apply rounded-t-none;
      @apply border-t-0;
    }
  }
}
