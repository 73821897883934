.loading-spinner {
  &__spinner-container {
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply w-full;
  }

  &__spinner {
    @apply rounded-full;
    @apply w-8;
    @apply h-8;
    border: 2px solid #2960c54d;
    border-top: 2px solid #2960c5;
    border-right: 2px solid #2960c5;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
