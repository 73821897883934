@use '/styles/mixin/fonts.module.scss' as *;

.drive-cfs-enquiry {
  @apply w-full;
  @apply h-full;

  input[type='checkbox'] {
    &:checked + :global(.checkbox) {
      @apply bg-blue-dark;

      &:after {
        content: '';
        @apply absolute;
        @apply top-1;
        left: 2px;
        @apply bottom-0;
        @apply border-l-2;
        @apply border-b-2;
        @apply border-white;
        transform: rotate(-51deg);
        @apply w-3;
        @apply h-[6px];
      }
    }
  }

  // Overwrite input field styles
  input,
  textarea {
    @include font-text('mobile', 'medium');
    @apply text-base;

    // Overwrite field placeholder styles on different browsers
    &::placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder,
    &::-webkit-input-placeholder {
      @include font-text('mobile', 'medium');
    }

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      @apply m-0;
      -webkit-appearance: none;
      -moz-appearance: textfield;
    }
  }

  form {
    :global(.comments) {
      @apply w-full;
      @apply min-h-[104px];

      [class^='animated-input_drive-form-animated-input__label'] {
        @apply rounded-none;
        @apply w-full;
        @apply bg-white;
      }

      [class^='animated-input_drive-form-animated-input-container'] {
        @apply h-full;
      }

      [class^='animated-input_drive-form-animated-input__input'] {
        @apply pt-9;
        @apply h-full;
      }
    }

    // Overwrite checkbox input styles
    :global(.checkboxes) {
      @apply flex;
      @apply flex-col;
      @apply flex-grow;
      @apply items-start;

      h3 {
        @include font-text('desktop', 'medium');
        @apply mt-0;
        @apply mb-[18px];
        @apply text-gray-600;
      }

      :global(.label) {
        @apply w-full;
        @apply text-black;
        @apply mb-4;
      }

      :global(.checkbox) {
        @apply bg-blue-50;
        @apply border-blue-dark;
        @apply h-5;
        @apply w-5;
        flex: 0 0 1.25rem;
        @apply border-2;
        @apply box-border;
        @apply rounded;
      }

      // Overwrite styles for conditions
      :global(.tc) {
        @apply w-full;
        @include font-text('desktop', 'small');
      }
    }
  }
}
