.drive-modal-portal {
  &__bg-overlay {
    @apply absolute;
    @apply w-screen;
    @apply h-screen;
    @apply fixed;
    @apply top-0;
    @apply left-0;
    z-index: 1;
    @apply bg-black/80;
  }

  &__wrapper {
    @apply fixed;
    @apply top-0;
    @apply left-0;
    @apply w-screen;
    @apply h-screen;

    @apply flex;
    @apply items-center;
    @apply justify-center;
    @apply overflow-y-auto;

    &--animate {
      transform: translateY(100%);
      opacity: 0;
      transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    }
    @screen xs {
      @apply w-screen;
      @apply h-screen;
    }
    @screen lg {
      @apply p-4;
    }
    z-index: 99999999999;

    [class*='virtualVideo_drive-virtual-video'] {
      @apply w-full;
      @apply h-full;
      @apply p-0;
      @apply absolute;
      @apply top-0;
      @apply left-0;
    }

    :global(.drive-video-modal-wrapper) {
      @apply w-full;
      @apply relative;
      max-height: 254px;
      height: 254px;
      @apply p-0;
      margin-top: 30px;

      @screen md {
        max-height: 755px;
        height: 755px;
        @apply max-w-screen-md;
      }
      @screen lg {
        @apply max-w-screen-xl;
      }

      /* Resize video player in landscape mode */
      @media (min-width: 420px) and (max-width: 980px) and (max-height: 480px) {
        [class^='videoPlayer_drive-review__video-player__external-player'] {
          max-width: 80%;
          @apply relative;
          @apply h-full;
          @apply pb-0;
          @apply mt-0;
        }
        @apply h-full;
      }
    }
  }

  &__enquiry-wrapper {
    @apply h-auto;
    z-index: 99999999999;
    @media (min-width: 500px) {
      @apply w-screen;
      @apply h-screen;
    }
  }

  &__show {
    :global(.drive-enquiry-form--cfs) {
      @apply min-h-0;
      @apply my-0;
      max-height: 98dvh;
      @apply mb-5;
      @apply overflow-y-scroll;
    }
    [class^='enquiry_drive-enquiry-form__wrapper'] {
      min-height: 100dvh;
      @apply flex;
      @apply flex-col;
      @apply justify-start;
      // extra padding-bottom to avoid being covered by mobile's input and navigation at the screen bottom.
      @apply pb-48;
      @media (min-width: 501px) {
        @apply pb-24;
      }
      @screen md {
        @apply min-h-0;
      }
      [class^='enquiry_drive-enquiry-form__container'] {
        @apply flex-grow;
      }
    }

    :global(.enquire-thanks-wrapper) {
      @apply h-full;
      @apply flex;
      @apply content-center;
      @apply p-0;

      @media (max-width: 600px) {
        margin: -43px 0 0;
      }
    }

    [class^='drive-enquiry__enquiry-close-btn'] {
      @apply block;
      @apply w-56;
      margin: 3.3rem auto 0;
      @apply bg-white;
      @apply text-blue-dark;
      @apply font-semibold;
      @apply text-15px;
      @apply p-3;
      @apply rounded-lg;
    }
    &--animate {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__close-wrapper {
    @apply absolute;
    @apply top-0;
    @apply left-0;
    @apply w-full;
    @apply flex;
    @apply justify-start;
  }

  &__close-hide {
    @apply h-full;
    @apply w-full;
    @apply bg-black/80;
  }

  &__close {
    @apply flex;
    @apply items-center;
    @apply text-white;
    @apply cursor-pointer;
  }

  &__close-icon {
    @apply text-3xl;
    @apply fill-current;
    @apply mr-2;
    @apply rounded-full;
    @apply z-4;
  }

  &__close-border {
    @apply border-2;
  }
}

.drive-modal-close-btn-wrapper {
  @apply relative;
  @apply top-[-2rem];
  @apply left-[1.063rem];
  @apply z-[1];

  @screen md {
    @apply left-[1rem];
    @apply top-[-1.8rem];
  }
  @screen xl {
    @apply left-[-2rem];
    @apply top-[-1.4rem];
  }
  @media (min-width: 1240px) and (max-width: 1440px) {
    @apply left-[1rem];
    @apply top-[-1.8rem];
  }
  @screen xxl {
    @apply left-[-2rem];
    @apply top-[-2.2rem];
  }
  @media (min-width: 420px) and (max-width: 980px) and (max-height: 480px) {
    @apply top-[1rem];
    @apply left-[-2rem];
  }
}

.drive-modal-close-btn {
  @apply w-full;
  @apply flex;
  @apply items-center;
  @apply text-white;

  :global(.drive-icon-CloseIcon) {
    @apply w-[1.375rem];
    @apply h-[1.375rem];

    @screen md {
      @apply w-7;
      @apply h-7;
    }
  }
}
