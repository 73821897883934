.d-container {
  &__ad-slot {
    @apply w-15;
    @apply h-[55px];
    @apply relative;
    animation: slideUp 0.5s ease-out forwards;
    // Add a delay based on the position of the ad-slot
    &:nth-child(1) {
      animation-delay: 0.1s;
    }
    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.3s;
    }
    &:nth-child(4) {
      animation-delay: 0.4s;
    }
    &:nth-child(5) {
      animation-delay: 0.5s;
    }
    &--enquiry {
      @apply flex;
      @apply flex-col;
      @apply justify-between;
      @apply items-center;
    }
  }
  &__ad-slot-skeleton {
    @apply px-2;
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply absolute;
    @apply w-full;
    z-index: 0;
  }
  &__ad-wrapper {
    @apply w-15;
    @apply h-[55px];
    @apply relative;
    @apply z-10;
  }
}
