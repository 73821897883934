@use '/styles/mixin/fonts.module.scss' as *;

.drive-award-icons {
  &__wrapper {
    @apply absolute;
    @apply flex;
    @apply top-1;
    @apply left-2;
  }
  &__dcoty-winner {
    @apply w-8;
    @apply h-8;
    @apply p-[7px];
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply z-10;
    @apply ml-[-0.3rem];
    @apply border;
    @apply border-gray-300;
    @apply rounded-full;
    @apply bg-black;
    @apply text-white;
    @apply text-lg;
    @apply relative;
    @apply cursor-pointer;

    &:global(.overall) {
      @apply bg-gold-primary;
    }

    :global(.tooltip) {
      @apply opacity-100;
      @apply absolute;
      @apply w-[280px];
      @apply top-[2.8rem];
      @apply -left-4;
      @apply z-80;
      @apply px-6;
      @apply py-3;
      @apply bg-teal-normal;
      @apply text-white;

      @screen md {
        @apply hidden;
      }

      h5 {
        @include font-subtext('desktop', 'medium');
        @apply relative;
        @apply z-10;
        @apply mt-0;
      }

      h6 {
        @include font-text('desktop', 'medium');
        @apply relative;
        @apply z-10;
        @apply mb-0;
      }

      &:after {
        content: '';
        @apply absolute;
        @apply w-[26px];
        @apply h-[26px];
        @apply bg-teal-normal;
        @apply top-[-9px];
        @apply left-[1.2rem];
        transform: rotate(45deg);
        @apply z-4;
      }
    }

    @screen md {
      &:hover {
        @apply text-white;
        :global(.tooltip) {
          @apply block;
        }
      }
    }
  }
}
