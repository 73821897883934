@use '/styles/mixin/fonts.module.scss' as *;
.drive-model-card__price {
  @apply mb-3;
  @include font-subtext('mobile', 'large');
  @media (min-width: 980px) {
    @include font-subtext('desktop', 'medium');
    @apply mb-2;
  }

  &-type {
    @include font-caption('mobile', 'bold');
    @apply text-gray-400;
    @media (min-width: 980px) {
      @include font-caption('desktop', 'normal');
    }
  }
}
