@use '/styles/mixin/imageWrapper.module.scss' as *;
.drive-showroom-top-card {
  &__ad {
    @apply w-full;
    @apply flex;
    @apply flex-col;
    @apply items-center;
  }
  &__inread-sticky {
    @apply bg-gray-100;
    @apply sticky;
    @apply top-0;
    @apply z-50;
    @apply overflow-visible;
    @screen md {
      @apply relative;
    }
    @screen lg {
      @apply bg-transparent;
    }
  }
  &__ad-hr {
    @apply m-0;
    @apply w-full;
    border: none;
    height: 1.63rem; //26 pixel, plus 10 pixel from ad makes 36
    @screen md {
      @apply hidden;
    }
  }
  &__wrapper {
    @apply flex;
    @apply flex-col;
    @apply mt-4;
    @apply p-5;
    @apply bg-white;
  }
  &__last {
    @apply mb-4;
  }
  &__make-wrapper {
    @apply flex;
    @apply justify-between;
    @apply mb-3;
  }
  &__make-container {
    @apply flex;
    @apply items-center;
    @apply overflow-hidden;
  }
  &__make {
    @apply mr-4;
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply border;
    @apply border-gray-300;
    @apply rounded-full;
    @apply w-12;
    @apply h-12;
    @apply text-xs;
  }

  &__make-title {
    @apply flex;
    @apply text-2xl;
    @apply m-0;
  }
  &__awards {
    @apply items-center;
    @apply hidden;
    @screen md {
      @apply flex;
    }
  }
  &__details {
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply justify-center;
    @screen md {
      @apply flex-row;
    }
  }
  &__img {
    @apply max-w-72;
    @apply mb-4;
    @screen md {
      @apply mb-0;
      @apply ml-2;
    }
  }

  &__deals {
    @apply flex;
    @apply items-end;
  }
  &__deals-icon-wrapper {
    @apply flex;
    @apply items-center;
    @apply justify-center;
    @apply text-white;
    @apply text-xl;
    @apply rounded-full;
    @apply w-8;
    @apply h-8;
    @apply bg-coral-normal;
    @apply mr-2;
  }
  &__deals-icon {
    @apply fill-current;
  }
  &__deals-price {
    @apply flex-1;
    @apply flex;
    @apply flex-col;
  }
  &__deals-count {
    @apply text-gray-400;
    @apply text-sm;
    @apply m-0;
    @apply mt-1;
  }
  &__deals-price-range {
    @apply w-full;
    @apply flex;
    @apply justify-between;
    @apply items-center;
    @apply my-1;
  }
  &__price-type {
    @apply text-gray-400;
    @apply text-sm;
  }
  &__like-dont-like {
    @apply border-t;
    @apply border-gray-200;
    @screen md {
      @apply ml-10;
    }
  }
  &__modal {
    @apply w-full;
    @apply flex;
    @apply flex-col;
    @apply justify-between;
    @apply border-t;
    @apply border-gray-200;

    @apply pt-6;
    @screen md {
      @apply flex-row;
    }
  }
  &__rating {
    @apply flex;
    @apply justify-between;
    @apply items-center;
  }
  &__rating-link {
    @apply ml-2;
  }
  &__modal-wrapper {
    @apply flex-1;
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply justify-end;
    @apply mt-2;
    @screen md {
      @apply flex-row;
    }
  }
  &__showroom-link {
    @apply w-full;
    @apply my-4;
    @screen md {
      @apply w-auto;
      @apply my-0;
      @apply mx-4;
    }
  }
  &__showroom-btn {
    @apply w-full;
    &:last {
      @apply m-0;
    }
  }
  &__modal-close-btn {
    @apply absolute;
    @apply left-auto;
    @apply right-0;
    @apply top-0;
    @apply p-1;
  }
  &__modal-anchor {
    @apply w-full;
    @screen lg {
      @apply w-auto;
    }
  }
  &__cars {
    @apply pt-12;
  }
  &__img-wrapper {
    @include img-wrapper;
  }
}

.drive-showroom-top-card__award {
  &__wrapper {
    @apply flex;
    @apply items-center;
    @apply justify-center;
    @apply text-white;
    @apply text-xl;
    @apply rounded-full;
    @apply w-8;
    @apply h-8;
    @apply bg-black;
    @apply ml-2;
  }
  &__icon {
    @apply fill-current;
  }
}
