@use '/styles/mixin/hero-grid.module.scss' as *;
.drive-hero-grid {
  &__wrapper {
    @apply flex flex-col relative mx-auto w-full;

    > :nth-child(n + 2) {
      @apply hidden; // Hide all images except the first one on mobile
      @screen lg {
        @apply block;
      }
    }

    img {
      @apply cursor-pointer max-w-[100vw];
    }
    [class*='articleHeroCarousel_drive-article-hero-carousel__container'] {
      @include applyGridGlobalDimension();
    }

    div[data-glide-el='controls[nav]'] {
      @apply bottom-10;
    }
    /* overwrite lightBox button per design */
    [class*='lightbox_drive-lightbox__open-btn'] {
      @apply bg-white;
      @screen lg {
        @apply bottom-0;
        @apply mb-4;
      }
    }
  }

  &__item-wrapper {
    @apply w-full h-full relative;
    > img {
      @apply w-full;
      @apply h-full;
      @apply max-h-[442px];
      @apply object-cover;
      @apply max-w-none;
      @apply object-center;
    }
  }

  &__default {
    overflow: hidden;
    @apply max-w-full;
    [class^='heroGrid_drive-hero-grid__item-wrapper'] {
      > img {
        @include applyGridDefaultDimension();
      }
    }
    [class*='articleHeroCarousel_drive-article-hero-carousel__container'] {
      @include applyGridDefaultDimension();
    }
    [class*='videoPlayer_drive-review__video-player__img'] {
      @include applyGridDefaultDimension();
    }

    @screen xl {
      @apply flex justify-center items-center;
    }

    > * {
      @apply w-full;
      @apply h-full;
    }
  }

  &__full-width {
    @screen lg {
      position: relative;
      left: 50%;
      right: 50%;
      width: 100vw;
      @apply max-w-none;
      @apply max-h-[680px];
      overflow: hidden;
      margin-left: -50vw;
      margin-right: -50vw;
      @apply bg-black;
      @apply flex;
      align-items: center;
      justify-content: center;

      > * {
        @apply w-full;
        @apply max-w-none;
        @apply m-auto;
        > * {
          @apply max-w-full m-auto static;
          @screen xxl {
            @apply max-w-[1920px];
            position: relative;
            @apply max-h-[680px];
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    [class*='videoPlayer_drive-review__video-player__img'] {
      @include applyGridFullWidthDimension();
    }
    [class*='articleHeroCarousel_drive-article-hero-carousel__container'] {
      @include applyGridFullWidthDimension();
    }
    [class^='heroGrid_drive-hero-grid__item-wrapper'] {
      > img {
        @include applyGridFullWidthDimension();
      }
    }
  }

  &__comparison {
    grid-template-columns: repeat(2, 1fr);
    @apply flex-row;
    justify-content: center;
    @screen xl {
      @apply grid;
    }
    > * {
      flex: 1;
      @apply w-full object-cover;

      @screen lg {
        @apply max-w-[600px];
      }

      &:first-child {
        @screen lg {
          @apply mr-4;
        }
      }
    }

    [class^='heroGrid_drive-hero-grid__item-wrapper'] {
      > img {
        @include applyGridComparisonDimension();
      }
    }
    [class*='articleHeroCarousel_drive-article-hero-carousel__container'] {
      @include applyGridComparisonDimension();
    }
  }
  &__multi-image-grid-a,
  &__multi-image-grid-b {
    @apply block;
    @apply max-w-full;
    > * {
      @apply w-full;
      @apply h-full;
      @apply object-cover;
    }

    [class^='heroGrid_drive-hero-grid__item-wrapper'] {
      > img {
        @include applyGridImageABDimension();
      }
    }

    [class*='videoPlayer_drive-review__video-player__img'] {
      @include applyGridImageABDimension();
    }

    @screen lg {
      display: grid;
      grid-gap: 14px;
      overflow: hidden;
      > * {
        @apply max-w-full;
      }
    }

    @screen xl {
      grid-gap: 16px;
    }
    [class*='videoPlayer_drive-review__video-player__modal-anchor'] {
      @apply max-h-[240px];
      @screen sm {
        @apply max-h-[442px];
      }
      @screen lg {
        @apply max-h-[335px];
      }
      @screen xl {
        @apply max-h-[395px];
      }
    }
  }

  &__multi-image-grid-a {
    @screen lg {
      grid-template-columns: max-content 1fr 1fr;
      grid-template-rows: repeat(2, 1fr);

      > :first-child {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        @apply w-[600px];
      }
    }
    @screen xl {
      > :first-child {
        @apply w-[703px];
      }
    }
  }

  &__multi-image-grid-b {
    @screen lg {
      grid-template-columns: 1fr max-content 1fr;
      grid-template-rows: repeat(2, 1fr);

      > :first-child {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        @apply w-[600px];
      }
    }
    @screen xl {
      > :first-child {
        @apply w-[703px];
      }
    }
  }

  &__light-box-button-wrapper {
    @apply top-0 left-0 bottom-0 right-0 absolute flex justify-center;
    align-items: center;
    .drive-hero-grid__light-box-button-centered {
      @apply m-auto;
      bottom: auto;
      right: auto;
    }
    &-background {
      @apply bg-black bg-opacity-30 transition-colors duration-300 ease-in-out;
    }
  }
  &__light-box-button-centered {
    @apply absolute min-w-[140px];
    white-space: nowrap;
  }
  &__legacy-type-video {
    pointer-events: none;
    > * {
      pointer-events: auto; // Allow child button still to be clickable
    }
  }
  &__play-icon-wrapper {
    @apply bg-white;
  }
}
