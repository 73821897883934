@use '/styles/mixin/fonts.module.scss' as *;

.drive-header-section {
  &__container {
    @apply flex;
    @apply flex-col;
    @apply w-full;
    @apply bg-blue-50;
    @apply rounded-2xl;
    @apply rounded-b-none;
    @apply px-6;
    @apply pt-7;
    @screen lmd {
      @apply px-8;
      @apply pt-10;
    }
  }

  &__wrapper {
    @apply flex;
    @apply w-full;
    @apply flex-row;
    @apply py-7;
    @screen lmd {
      @apply pt-0;
      @apply pb-6;
    }
  }

  &__description {
    @apply flex;
    @apply flex-col;
    @apply pl-4;
    @apply mt-1;
    @screen lmd {
      @apply flex-row;
      @apply pl-6;
    }
  }

  &__separator {
    @apply m-0;
    @apply w-full;
    @apply border-b;
    @apply border-blue-light;
    @apply mx-auto;
  }
  &__header {
    h3 {
      @apply m-0;
      @include font-subtext('mobile', 'medium');
    }
    p {
      @apply m-0;
      @apply mt-1;
      @include font-text('mobile', 'medium');
    }

    @screen lmd {
      @apply min-w-[296px];
      h3 {
        @apply m-0;
        @apply mt-[10px];
        @include font-subtext('desktop', 'large');
      }
      p {
        @apply m-0;
        @apply mt-2;
        @include font-text('desktop', 'medium');
      }
    }
  }
  &__img-wrapper {
    @apply h-full;
    @apply flex;
    @apply items-center;
    @apply justify-center;
    @apply min-w-[92px];
    @screen lmd {
      @apply min-w-[129px];
    }
    @apply relative;
  }

  &__sample-image-text {
    @apply text-center;
    @apply p-1px;
    @apply absolute;
    @apply bottom-0;
    @apply w-full;
    @include font-subtext('desktop', 'small');
    @apply bg-blue-faint;
    @apply text-blue-dark;
  }

  &__separator-header {
    @apply w-[62px];
    @apply border-x-2;
    @apply border-y-[2.5px];
    @apply border-blue-light;
    @apply rounded-lg;
    @apply mx-auto;
    @apply -mt-[4px];
    @screen lmd {
      @apply hidden;
    }
  }
}
