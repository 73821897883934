.tooltip-text {
  @apply font-semibold;
  @apply font-primary;
  @apply text-13px;
  @apply text-white;
  @apply p-1;
}

.tooltip-text::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 42%;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #f55e55 transparent;
}

.tooltip {
  position: relative;
  min-width: 120px;
  text-align: center;
  top: 43px;
  @apply bg-coral-normal;
  @apply shadow-md;
  @apply rounded-sm;
  z-index: 10;
}
