@use '/styles/mixin/fonts.module.scss' as *;

.drive-listing-image-info {
  &__img {
    @apply hidden;
    @screen lg {
      @apply flex;
      @apply h-20;
    }
  }

  &__car-details {
    @apply hidden;
    @screen lg {
      @apply w-full;
      @apply flex;
      @apply flex-1;
      @apply items-start;
      @apply justify-start;
      @apply mx-5;
      @apply cursor-default;
    }
    @screen xl {
      @apply mx-6;
    }

    &__name {
      @apply flex;
      @apply flex-col;
      @apply items-start;
      @apply justify-center;
      @apply gap-y-0.5;

      @screen lg {
        @apply w-[270px];
      }

      @screen xl {
        @apply w-[340px];
      }

      &__title {
        @include font-subtext($device: $device-mobile, $type: 'large');

        @screen lg {
          @include font-heading($device: $device-desktop, $type: 'h4');
        }
      }
      &__short-description {
        @screen lg {
          @include font-text($device: $device-desktop, $type: 'medium');
        }
      }
    }
  }
}
