@use '/styles/mixin/fonts.module.scss' as *;

.drive-contact-seller-cta {
  &__btns {
    @apply flex;
    @apply w-full;

    @apply px-5;
    @screen md {
      @apply w-auto;
    }
    @screen lg {
      @apply px-0;
    }
  }

  &__contact-seller-btn {
    @apply bg-blue-dark;
    border-radius: 0.5rem;
    @include font-button;
    @apply w-full;
    @apply h-12;
    @apply flex;
    @apply items-center;
    @apply justify-center;
    @apply flex-nowrap;
    @apply whitespace-nowrap;
    @apply px-[21px];
    @apply min-w-[152px];
    @apply flex-1;
    @screen lg {
      @apply w-[200px];
    }
    @screen xl {
      @apply w-[220px];
    }
    &:hover {
      @apply bg-blue-darkest;
    }
    &--number-cta {
      @apply border-[2px];
      @apply px-[19px];
      @apply border-blue-dark;
      @apply mr-4;
      @apply text-blue-dark;
      @apply bg-white;

      @screen lg {
        @apply border-gray-500;
        @apply text-white;
        @apply bg-black;
      }
      @screen xl {
        @apply mr-6;
      }
    }

    &--number-cta:hover {
      @apply bg-white;
      @apply border-[#497bd8];

      @screen lg {
        @apply border-white;
        @apply bg-black;
      }
    }

    //when there is only one button
    &:first-child:last-child {
      @apply bg-blue-dark;
      @apply text-white;
      @apply border-0;
      @screen lg {
        @apply max-w-60;
        @apply w-60;
        @apply ml-0;
      }

      &:hover {
        @apply bg-blue-darkest;
      }
    }

    &__icon {
      &--mobile-icon {
        @apply inline;
        @apply min-w-[25px];
        @screen lg {
          @apply hidden;
        }
      }
      &--desktop-icon {
        @apply hidden;
        @screen lg {
          @apply inline;
          @apply min-w-[25px];
        }
      }
    }

    &__text {
      @apply ml-2;
      &--mobile {
        @apply inline;
        @screen lg {
          @apply hidden;
        }
      }
      &--non-mobile {
        @apply hidden;
        @screen lg {
          @apply inline;
        }
      }
    }
    [class^='loading-spinner_loading-spinner__spinner'] {
      @apply border-t-white;
      @apply border-r-white;
      @apply opacity-80;
    }
  }
}
