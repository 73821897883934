.drive-deals-info {
  &__container {
    @apply flex;
    @apply justify-between;
    @apply items-center;
  }

  &__count {
    @apply text-coral-normal;
    @apply font-bold;
  }

  &__icon {
    @apply w-8;
    @apply h-8;
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply rounded-full;
    @apply bg-coral-normal;
    @apply text-white;
    @apply text-xl;
  }
}
