@use '/styles/mixin/thumbnail.module.scss' as *;
@use '/styles/mixin/imageWrapper.module.scss' as *;
@use '/styles/mixin/fonts.module.scss' as *;
.drive-review__cfs {
  &__wrapper {
    @apply relative;
    @include article-thumbnail-compact;
  }
  &__figure {
    @apply bg-gray-100;
    @apply flex;
    @apply items-center;
    @apply justify-center;

    width: 8.75rem;
    height: 5.9rem;
    @apply w-full;

    &[class~='author'] {
      @apply bg-white;

      img {
        @apply h-full;
      }
    }
  }
  &__content {
    @apply w-auto;
    @apply flex;
    @apply flex-col;
    @apply justify-between;
  }
  &__content-compact {
    @apply pl-3;
    @apply pr-2;
    @apply py-2;
  }
  &__content-full {
    @apply pl-6;
    @apply pr-2;
    @apply py-1;
  }
  &__name {
    @apply text-black;
    @apply capitalize;
    @apply min-h-10;
  }

  &__price-wrapper {
    @apply flex;
    @apply items-center;
    @screen lg {
      @apply justify-start;
    }
  }
  &__price-type {
    @apply mb-1;
    @apply w-full;
    @screen md {
      // @apply sub-2-desk
    }
  }
  &__price {
    @apply text-gray-400;
    @apply mb-1;
    @apply ml-2;
    @apply text-left;
  }

  &__img-wrapper {
    @include img-wrapper;
  }
  &__articles-heading {
    @apply mt-0;
    @apply mb-6;
    @apply break-normal;
    @include font-heading('mobile', 'h3');
    @apply font-semibold;
    @screen md {
      @include font-subtext('desktop', 'medium');
    }
  }
}

.drive-review__cfs__related {
  &__price-wrapper {
    @apply flex;
    @apply justify-between;
    @apply items-center;
    @apply mb-1;
  }

  &__price {
    @apply flex;
    @apply gap-x-0.5;
  }
  &__price-value {
    @apply flex;
    @include font-caption('desktop', 'normal');
    @apply text-gray-400;
  }

  &__link {
    @apply w-full;
    @apply mt-2;
    @apply mb-3;
    @apply min-h-4;
    @screen lg {
      @apply ml-0;
    }
  }
}
