@use '/styles/mixin/fonts.module.scss' as *;

.drive-price-drop-label {
  @apply h-[22px];
  @apply w-[94px];
  @apply bg-[#E5F7F7];
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply gap-[5px];
  @apply rounded-sm;
  @apply pointer-events-none;
  &__text {
    @include font-caption('desktop');
    @apply font-medium;
    @apply text-teal-dark;
  }
}
