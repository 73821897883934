@use '/styles/mixin/fonts.module.scss' as *;

.sticky-wrapper {
  @apply sticky;
  @apply top-0;
  @apply z-60;
  @apply pt-6;
  @apply bg-white;
  // @apply hidden; // TODO: Remove this after Mike has done testing for impression tracking
}

.d-container {
  @apply relative;
  @apply border-2;
  @apply border-gray-300;
  @apply rounded-2xl;
  @apply px-6;
  @apply pt-4;
  @apply pb-[18px];
  @apply hidden;
  @screen lg {
    @apply block;
  }
  // DCOTY Icon
  [class*='awardIcon_drive-award-icons__wrapper'] {
    @apply right-1;
    @apply left-auto;
  }
  // Model - Name & Image
  &__model {
    @apply flex;
    @apply gap-[2px];
    @apply mb-2;
  }
  &__model-name {
    @apply text-blue-dark;
    @apply flex-1;
    @include font-subtext('desktop', 'large');
  }
  &__model-image-wrapper {
    @apply w-[120px];
    @apply h-16;
  }
  &__model-image {
    @apply w-full;
    @apply h-auto;
    @apply object-cover;
    @apply bg-white;
  }
  // Model - Price & Deals
  &__model-info {
    @apply flex;
    @apply justify-between;
    @apply items-end;
    @apply mb-4;
  }
  &__model-price-wrapper {
    @apply flex;
    @apply flex-col;
    @apply items-start;
  }
  &__model-price-mrlp {
    @apply text-gray-400;
    @include font-caption('mobile', 'normal');
  }
  &__model-price-range {
    @apply text-black;
    @include font-subtext('desktop', 'large');
  }
  &__model-deals {
    @apply px-2;
    @apply py-1;
    @apply rounded-3xl;
    @apply text-white;
    @apply bg-teal-normal;
    @include font-caption('desktop', 'normal');
  }
  // CTA List
  &__ctas-container {
    @apply flex;
    @apply gap-2;
  }
  &__cta-wrapper {
    @apply flex;
    @apply items-center;
    @apply bg-blue-darkest;
    @apply rounded-md;
    @apply w-18;
    @apply h-20;
    &--enquire-now {
      @apply flex;
      @apply flex-col;
      @apply justify-center;
      @apply items-center;
      @apply gap-2;
    }
    > div {
      @apply mx-auto;
    }
  }
  &__cta-icon-wrapper {
    @apply w-6;
    @apply h-6;
  }
  &__cta-text {
    @apply text-white;
    @apply text-center;
    @apply text-11px;
    line-height: 14px;
    @apply font-medium;
    @apply px-1;
  }
}
