@mixin applyGridDefaultDimension {
  @apply max-h-[240px];
  @apply container;
  @apply w-full-screen;
  @screen sm {
    @apply max-h-[440px];
  }
}

@mixin applyGridFullWidthDimension {
  @apply max-h-[240px];
  @apply max-w-[1920px];
  @apply w-full-screen;
  @screen sm {
    @apply max-h-[442px];
  }
  @screen lg {
    @apply max-h-[400px];
  }
  @screen xl {
    @apply max-h-[456px];
  }
  @screen l {
    @apply max-h-[544px];
  }
  @screen xxl {
    @apply max-h-[680px];
  }
}

@mixin applyGridComparisonDimension {
  @apply max-h-[240px];
  @screen sm {
    @apply max-h-[442px];
  }
  @screen lg {
    @apply max-h-[269px];
  }
  @screen xl {
    @apply max-h-[343px];
  }
}

@mixin applyGridGlobalDimension {
  @apply max-h-[240px];
  @screen sm {
    @apply max-h-[442px];
  }
  @screen lg {
    @apply max-h-[335px];
    @apply h-auto;
  }
  @screen xl {
    @apply max-h-[400px];
  }
}

@mixin applyGridImageABDimension {
  @apply max-h-[240px];
  @screen sm {
    @apply max-h-[442px];
  }
  @screen lg {
    @apply max-h-[335px];
  }
  @screen xl {
    @apply max-h-[400px];
  }
}
