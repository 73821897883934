@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/tickMark.module.scss' as *;

.drive-cfs__confirmation-message {
  &__wrapper {
    @apply bg-blue-50;
    @apply rounded-2xl;
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply justify-start;
    @apply w-full;
    @apply px-6;
    @apply pt-16;
    @apply pb-12;

    @screen xs {
      @apply min-w-[312px];
    }

    @screen md {
      @apply rounded-2xl;
      @apply pt-10;
      @apply pb-8;
      @apply px-[44px];
    }

    @screen lg {
      @apply px-15;
    }
  }

  &__heading {
    @apply text-blue-dark;
    @include font-heading('desktop', 'h3');
    @apply mt-2;
    @apply mb-0;

    @screen md {
      @include font-heading('mobile', 'h3');
    }

    @screen lg {
      @include font-heading('desktop', 'h3');
    }
  }

  &__msg {
    @include font-text('desktop', 'medium');
    @apply text-center;
    @apply mt-6;
    @apply mb-0;
    @apply w-full;
    @apply max-w-[560px];

    @screen md {
      @include font-text('mobile', 'medium');
      @apply mt-[18px];
    }

    @screen lg {
      @include font-text('desktop', 'medium');
      @apply mt-6;
    }
  }

  &__cta-blocks {
    @apply w-full;

    //We will need fix height of the CMS container so that the component does not
    //flicker if the CMS blocks takes time to render
    @apply min-h-[440px]; //192*2 (2 blocks) + 24(gap) + 32(top margin)

    @apply max-h-[656px]; //300*2 + 56 = 24(gap) + 32(top margin)

    //2*(prev calc) 2 because of 2 columns, 56 = 24(gap) + 32(top margin)
    height: calc(2 * (-50px + 60vw) + 56px);

    @screen md {
      @apply min-h-[216px];
      @apply h-[216px];
    }

    [class^='blocks_drive-block__wrapper'] {
      @apply mt-8;
      @apply w-full;
    }

    :global(.drive-confirmation-ctas) {
      @apply flex;
      @apply items-center;
      @apply justify-center;
      @apply relative;
      @apply w-full;
      @apply py-0;
      @apply w-full;
      @apply mb-0;

      @screen md {
        @apply flex-row;
        flex-wrap: nowrap !important;
        @apply gap-x-4;
      }

      :global(.wp-block-column) {
        @apply opacity-0;
        animation-name: dissolve;
        animation-duration: 1s;
        animation-fill-mode: forwards;

        &:nth-child(1) {
          animation-delay: 0s;
          @apply mb-6;

          @screen md {
            @apply mb-0;
          }
        }

        &:nth-child(2) {
          animation-delay: 0.7s;
        }
      }

      :global(.wp-block-cover) {
        @apply min-h-[192px];
        @apply max-h-[300px];
        height: calc(-50px + 60vw);
        @apply p-7;

        @screen md {
          @apply p-6;
          @apply min-h-[184px];
          @apply h-[184px];
        }

        img {
          @apply rounded-2xl;
        }
      }

      :global(.wp-block-cover__background) {
        background-color: rgba(0, 0, 0, 0.2) !important;
        @apply rounded-2xl;
      }

      :global(.wp-block-cover__inner-container) {
        @apply flex;
        @apply flex-wrap;
        @apply justify-center;
        @apply items-center;

        :global(.drive-image) {
          @apply flex;
          @apply justify-center;
          @apply w-full;

          img {
            @apply rounded-none;
            @apply w-8;
            @apply h-8;
          }
        }

        h3 {
          @apply w-full;
          @apply text-white;
          @apply text-center;
          @include font-heading('desktop', 'h4');
          @apply mt-2;
          @apply mb-6;
        }

        :global(.wp-block-buttons) {
          @apply w-full;
          @apply flex;
          @apply justify-center;

          a {
            @apply h-12;
            @apply px-2;
            @apply w-[220px];
            @apply text-black;
            @apply bg-white;

            strong {
              @include font-button;
              @apply flex;
              @apply justify-center;
              @apply items-center;
            }
          }
        }
      }

      :global(.wp-block-cover__background::before) {
        @apply rounded-2xl;
      }
    }
  }

  @keyframes dissolve {
    from {
      @apply opacity-0;
    }
    to {
      @apply opacity-100;
    }
  }
}
