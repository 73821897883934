@use '/styles/mixin/tickMark.module.scss' as *;

.drive-tick-mark {
  &__checkmark {
    @include checkmark;
  }

  &__checkmark__circle {
    @include checkmark-circle;
  }

  &__checkmark__check {
    @include checkmark-check;
  }
}
