@use '/styles/mixin/fonts.module.scss' as *;

.drive-cfs-form-price-info {
  &__wrapper {
    @apply mt-1;
    @apply flex;
    @apply gap-x-2;
    @include font-heading($device: $device-desktop, $type: 'h4');
    @apply flex-col;
    > [class^='priceDropLabel_drive-price-drop-label'] {
      @apply bg-teal-normal;
      @apply w-6;
      @apply h-6;
      @apply rounded;
      @screen md {
        @apply w-[100px];
        @apply mt-2;
      }
    }
  }
  &__price-container {
    @apply flex;
    @apply items-center;
  }
  &__drop-price {
    @apply line-through;
    @apply text-gray-400;
    @apply text-xs;
    @apply font-semibold;
    line-height: 20px;
    @screen md {
      @include font-subtext('desktop', 'medium');
      @apply font-medium;
      line-height: 32px;
      @apply pl-2;
    }
  }

  &__drop-price-label {
    @apply w-6;
    @apply h-6;
    @apply rounded;
    @apply bg-teal-normal;
    @screen md {
      @apply w-[100px];
      @apply h-6;
      @apply mt-2;
    }

    [class^='priceDropLabel_drive-price-drop-label__text'] {
      @apply hidden;
      @screen md {
        @apply block;
        @include font-subtext('mobile', 'medium');
        line-height: 24px;
        @apply text-white;
      }
    }
  }
  &__price {
    @apply mr-1;
    @include font-subtext('mobile', 'medium');
    @screen md {
      @include font-heading('desktop', 'h4');
    }
  }
  &__price-type {
    @include font-text('mobile', 'small');
    @apply flex;
    @apply gap-x-0.5;
    [class*='disclaimer-tooltip'] {
      @apply flex;
      @apply self-baseline;
    }
    @screen md {
      @include font-text('desktop', 'small');
      [class*='disclaimer-tooltip'] {
        @apply self-center;
      }
    }
  }

  &__indicate-price {
    @include font-text('mobile', 'small');
    @apply mr-2;
    @screen xl {
      @apply mr-2.5;
      @include font-text('desktop', 'medium');
    }
  }
}
