@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/articles.module.scss' as *;

.drive-review-single {
  &__wrapper {
    @apply px-grid2s;
  }
  &__articles {
    @apply max-w-full;
    @apply flex-1;
    @apply mb-8;
    @apply min-w-72;

    @screen lg {
      @apply max-w-[656px];
    }

    @screen xl {
      @apply max-w-[805px];
    }
  }

  &__main-body {
    @apply max-w-[703px];
    @apply mx-auto;

    // Block styles
    [class*='drive-block__ad__wrapper'],
    [class*='drive-block__ad__sticky'] {
      @screen xl {
        @apply relative;
        @apply overflow-visible;
        @apply w-[728px];
        left: calc(-364px + 50%);
      }
    }
    [class*='drive-block__ad__blocks-wrapper--gam_texttransact'] {
      @screen xl {
        @apply relative;
        @apply overflow-visible;
        @apply w-full;
        left: calc(-364px + 50%);
      }
    }
    [class*='d-marketplace-listing-block'] {
      @screen l {
        @apply overflow-hidden;
        width: 805px;
        left: calc(-403px + 50%);
      }
    }
    [class*='drive-post__gallery__inline-carousel'] {
      @screen l {
        @apply overflow-visible;
        @apply w-[805px];
        left: calc(-403px + 50%);
      }
    }
  }

  &__title {
    @include font-heading('mobile', 'h2');
    @screen md {
      @include font-heading('desktop', 'h2');
      @apply border-b;
      @apply pb-4;
    }
  }
  &__owner {
    @apply mt-8;
  }
  &__article-title {
    @apply mt-3;
  }
  &__jump-links {
    @apply -mb-3;
    @apply border-b-0;
  }

  &__jump-links-empty {
    @apply pb-5;
  }
  &__links {
    @apply border-gray-300;
    @screen md {
      @apply border-b;
    }
  }
  &__ad-pos3 {
    @apply flex;
    @apply justify-center;
  }
  &__you-may-also-like {
    @include rightEdgedYMALSlides;
  }
  &__cfs {
    @apply py-8;
    @apply px-grid2s;
  }
  &__comment-wrapper {
    @apply px-grid2s;
  }
  &__ad-posR5-wrapper {
    @apply items-center;
  }
  &__ad-posR5 {
    @apply w-full;
    @apply hidden;
    @apply top-0;
    @apply z-50;
    @apply sticky;
    @apply overflow-visible;
    @screen md {
      @apply flex;
    }
  }

  &__fuelvoucher-pos1 {
    @apply flex;
    @apply justify-center;
  }
  &__quote-container {
    @apply flex;
    @apply h-[66px];
    @apply w-full;
    @screen md {
      @apply w-[50%];
    }
  }
  &__container {
    @screen md {
      :global(.dropdown) {
        :global(.content) {
          :global(.item) {
            @apply p-6px;
            @apply px-4;
            > a {
              @apply text-black;
              @apply font-semibold;
            }
          }
        }
        &:global(.similar-cars) {
          :global(.content) {
            :global(.item) {
              @apply p-6px;
              @apply px-4;
              > a {
                @apply text-blue-dark;
                @apply font-semibold;
              }
            }
          }
        }
      }
    }
    [class^='pageWrapper_drive-page-wrapper__breadcrumbs__3C6ac md:hidden'] {
      @apply mb-[0.438rem];
      @apply mt-1;
    }
    [class^='car-specs_drive-car-specs__list-container'] {
      @apply hidden;
    }
  }
  &__linked-guides-full-width {
    @include font-button('desktop');
    @apply flex;
    @apply rounded-[8px];
    @apply h-12;
    @apply justify-center;
    @screen md {
      @include font-subtext('desktop', 'medium');
      @apply justify-between;
      @apply rounded-none;
      @apply mb-4;
    }
  }
  &__fixedresponsive-container {
    @apply flex;
    @apply gap-[10px];
  }
  &__fixedresponsive-wrapper {
    @apply h-12;
    @apply w-full;
  }
}

.drive-review-single__side-bar {
  &__wrapper {
    @apply w-full;
    @apply max-w-360;
    @apply relative;
    @screen lg {
      @apply mt-gridL;
    }
  }
  &__ad-wrapper {
    @apply mx-auto;
  }
  &__ad-posR1 {
    @apply w-full;
    @apply hidden;
    @screen md {
      @apply flex;
    }
  }
  &__sticky-wrapper {
    @apply relative;
  }
  &__cfs-related-wrapper {
    @apply bg-white;
    @apply visible;
    @apply items-center;
    @apply z-50;
    @apply overflow-visible;
    @apply mb-2; // need this for See All link to show and not overlapped by next element
  }
  &__ad-posR2-wrapper {
    @apply bg-white;
    @apply visible;
    @apply items-center;
    @apply z-50;
    @apply overflow-visible;
    @apply sticky;
    @apply top-[246px];
  }
  &__ad-posR2 {
    @apply w-full;
    @apply hidden;
    @screen md {
      @apply flex;
    }
  }
  &__cfs-related {
    @apply mb-10;
  }
  &__annex-cards-wrapper {
    @apply bg-white;
    @apply visible;
    @apply items-center;
    @apply z-50;
    @apply overflow-visible;
  }
  &__annex-cards-container {
    @apply bg-white;
    @apply visible;
    @apply items-center;
    @apply z-50;
    @apply overflow-visible;
    @apply sticky;
    @apply top-[246px];
  }
  &__ad-posR3 {
    @apply w-full;
    @apply hidden;
    @screen md {
      @apply flex;
    }
  }
  &__annex-cards {
    @apply mb-10;
    @apply mx-auto;
  }
  &__compare-cards {
    @apply bg-white;
    @apply visible;
    @apply items-center;
    @apply z-50;
    @apply overflow-visible;
    @apply sticky;
    @apply top-[246px];
  }
  &__ad-posR4 {
    @apply w-full;
    @screen md {
      @apply flex;
    }
  }
}
