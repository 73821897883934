@use '/styles/mixin/fonts.module.scss' as *;

.drive-multi-reviews-drop-down {
  &__heading {
    @apply py-1;
    @apply text-black;
    @apply w-full;

    &--error {
      @apply text-gray-500;
    }
  }

  &__default-label {
    @include font-text('mobile', 'medium');

    @screen md {
      @include font-text('desktop', 'medium');
    }
  }

  &__list-item {
    @apply flex;
    @apply w-full;
    @apply justify-between;
    @apply items-center;

    @screen sm {
      @apply pr-10;
    }

    @screen xl {
      padding-right: calc(100% - 540px);
    }

    &--heading {
      @screen sm {
        @apply pr-[22px];
      }
      @screen xl {
        @apply max-w-[540px];
        @apply pr-0;
      }
    }

    &__name {
      @apply text-ellipsis;
      @apply whitespace-nowrap;
      @apply overflow-hidden;
      @include font-text('mobile', 'medium');

      @screen md {
        @include font-text('desktop', 'medium');
      }
    }

    &__price-details {
      @apply hidden;
      @screen sm {
        @apply flex;
        @apply items-center;
        @apply gap-2.5;
      }

      &--heading {
        @apply gap-1.5;
      }

      &__text {
        @apply flex;
        @apply text-gray-400;
        @include font-caption('mobile', 'normal');

        @screen md {
          @include font-caption('desktop', 'normal');
        }
      }

      &__amount {
        @apply ml-0.5;
        @include font-subtext('mobile', 'medium');
        @screen md {
          @include font-subtext('desktop', 'medium');
        }

        &--heading {
          @apply ml-1;
        }
      }
    }
  }

  &__dropdown {
    box-shadow: none !important;
    @apply w-full;
    @apply h-14;

    @screen lg {
      @apply max-w-[675px];
    }

    [class^='dropdown_drive-dropdown__error-msg'] {
      @apply -mb-4;
      @apply left-2;
      @apply text-coral-dark;
    }

    :global(.open) {
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
      @apply border-[1px];
    }

    :global(.input-field-error) {
      @apply mb-0 #{!important};
    }

    :global(.content) {
      @apply top-14;
      @apply border-0;
      @apply rounded-b-lg;
      @apply pt-0;

      :global(.items) {
        @apply -mt-2;
        @apply overflow-visible #{!important};
        @apply rounded-b-lg;

        :hover {
          &:last-child {
            @apply rounded-b-lg;
          }
        }
      }
    }
  }

  &__dropdown-item {
    @apply px-4;
    @apply py-3;

    &:nth-child(1) {
      @apply pt-5;
    }
  }
}
