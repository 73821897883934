.drive-deals-info-tooltip {
  &__container {
    @apply relative;
    @apply cursor-pointer;
    @apply -mt-2;
    @apply w-8;
    @apply h-8;
    @apply ml-1;
    @apply mr-8;
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply rounded-full;
    @apply bg-coral-normal;
    @apply text-white;
    @apply text-xl;
  }
}
