@mixin checkmark($color: white, $size: 40px) {
  width: $size;
  height: $size;
  @apply block;
  @apply rounded-[50%];
  stroke-width: 4;
  stroke: $color;
  stroke-miterlimit: 10;
}

@mixin checkmark-circle($color: #2960c5) {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 5;
  stroke-miterlimit: 10;
  stroke: $color;
  fill: $color;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

@mixin checkmark-check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
