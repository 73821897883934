@use '/styles/mixin/fonts.module.scss' as *;

.drive-contact-seller-bar__price-info {
  &__wrapper {
    @include font-heading($device: $device-desktop, $type: 'h4');
  }
  &__price-container {
    @apply flex;
  }
  &__drop-price {
    @apply pl-2;
    @include font-subtext('desktop', 'medium');
    @apply font-medium;
    line-height: 32px;
    @apply text-gray-400;
    @apply line-through;
  }

  &__drop-price-label[class^='priceDropLabel_drive-price-drop-label'] {
    @apply bg-teal-normal;
    @apply rounded;
    @apply w-[100px];
    @apply h-6;
    @apply mt-2;
    [class^='priceDropLabel_drive-price-drop-label__text'] {
      @include font-subtext('mobile', 'medium');
      line-height: 24px;
      @apply text-white;
    }
  }

  &__price {
    @apply mr-1;
    @apply pt-1;
  }
  &__price-type {
    @apply flex;
    @apply gap-x-0.5;
    @include font-text('desktop', 'small');
  }
}
