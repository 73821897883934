@use '/styles/mixin/imageWrapper.module.scss' as *;
@use '/styles/mixin/fonts.module.scss' as *;
.drive-model-card {
  @apply flex;
  @apply flex-col;
  @apply my-4;
  @apply p-5;
  @apply bg-white;
  @apply border;
  @apply border-gray-200;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.04));
  &__wrapper {
    @apply flex;
    @apply justify-between;
    @apply mb-3;
  }
  &__container {
    @apply flex;
    @apply items-center;
  }
  &__make-img {
    @apply mr-4;
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply border;
    @apply border-gray-300;
    @apply rounded-full;
    @apply w-12;
    @apply h-12;
    @apply text-xs;
  }
  &__make-name {
    @apply flex;
    @apply text-2xl;
    @apply m-0;
  }
  &__awards {
    @apply hidden;
    @apply items-center;
    @screen md {
      @apply flex;
    }
  }

  &__img-wrapper {
    @include img-wrapper;
  }

  &__img {
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply justify-start;
    @screen md {
      @apply flex-row;
    }

    :global(.model-card-opinion) {
      @apply self-stretch;
      @apply border-0;
      @apply shadow-none;
      @apply border-t;
      @apply border-gray-200;
      @screen md {
        @apply ml-[2.5rem];
        @apply w-full;
      }

      li {
        @apply mb-[12px];
      }
    }

    [class*='opinion-block_d-opinion-block__icon--positive'] {
      @apply bg-blue-secondary;
      span {
        @apply bg-blue-secondary;
      }
    }

    [class*='opinion-block_d-opinion-block__icon--negative'] {
      @apply bg-gray-400;
      span {
        @apply bg-gray-400;
      }
    }

    [class*='opinion-block_d-opinion-block__list--negative'] {
      span {
        @apply text-gray-400;
      }
    }

    [class*='opinion-block_d-opinion-block__wrapper'] {
      &:first-child {
        @apply border-0;
      }
    }

    [class*='priceRange_drive-model-card__price'] {
      @include font-subtext('desktop', 'medium');
    }
  }
  &__img-container {
    @apply mb-4;
    @apply max-w-full;
    @screen md {
      @apply max-w-[263px];
      @apply w-full;
      @apply m-0;
    }
  }
  &__deal {
    @apply flex;
    @apply items-end;
  }
  &__deal-wrapper {
    @apply flex;
    @apply items-center;
    @apply justify-center;
    @apply text-white;
    @apply text-xl;
    @apply rounded-full;
    @apply w-8;
    @apply h-8;
    @apply bg-coral-normal;
    @apply mr-2;
  }
  &__deal-logo {
    @apply fill-current;
  }
  &__variants-count {
    @apply flex-1;
    @apply flex;
    @apply flex-col;
  }
  &__variants-label {
    @apply text-gray-400;
    @apply text-sm;
    @apply m-0;
    @apply mt-1;
  }
  &__variant-price {
    @apply w-full;
    @apply flex;
    @apply justify-between;
    @apply items-center;
    @apply mt-1;
  }
  &__variant-price-type {
    @apply text-gray-400;
    @apply text-sm;
  }
  &__like-dont-like-block {
    @apply border-t;
    @apply border-gray-200;
    @screen md {
      @apply ml-10;
    }
  }
  &__content-wrapper {
    @apply w-full;
    @apply flex;
    @apply flex-col;
    @apply justify-between;
    @apply border-t;
    @apply border-gray-200;
    @apply pt-4;
    @apply mt-6;
    @apply justify-end;
    @apply mt-2;
    @screen md {
      @apply flex-row;
    }
  }
  &__btn-link {
    @apply w-full;
    @apply my-4;
    @screen md {
      @apply w-auto;
      @apply my-0;
      @apply mx-4;
    }
  }
  &__btn {
    @apply w-full;
    &:last {
      @apply m-0;
    }
  }

  &__modal-close-btn {
    @apply absolute;
    @apply left-auto;
    @apply right-0;
    @apply top-0;
    @apply p-1;
  }

  &__rating-wrapper {
    @apply flex;
    @apply justify-between;
    @apply items-center;
  }
  &__rating-link {
    @apply ml-2;
  }
  &__btn-wrapper {
    @apply flex-1;
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply justify-end;
    @apply mt-2;
    @screen md {
      @apply flex-row;
    }
  }
}
